import { lighten } from 'polished';

const colors = {
  // RoadGoat Colors
  pink: '#ff6daa',
  royalBlue: '#2275d7',
  navy: '#2D3988', // or #2b2e39
  lily: '#5A71FF',
  teal: '#00B5AD',
  darkYellow: '#f8bd2c', //or #FFAE28
  dangerRed: '#ff4d4f',
  skyBlue: '#f5f6fe',
  successGreen: '#87d068',

  // Semantic UI Yellows
  suiStarYellow: '#FFE623',
  suiStarOutline: '#DDC507',
  suiStarSelected: '#FFCC00',
  suiStarSelectedOutline: '#E6A200',
};

const rgTheme = {
  font: {
    readable: {
      'line-height': '20px',
      'font-family':
        'system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif',
      'font-weight': '400',
      'overflow-wrap': 'break-word',
      'white-space': 'pre-wrap',
      'word-break': 'break-word',
    },
  },

  color: {
    // Theme
    primary: colors.pink,
    primaryLight: lighten(0.2, colors.pink),
    secondary: colors.lily,
    secondaryLight: '#bcc7ff',
    tertiary: colors.navy,
    tertiaryLight: lighten(0.2, colors.navy),
    beenThere: colors.lily,
    beenThereLight: lighten(0.3, colors.lily),
    highlight: '#fde5b2',
    highlightLight: '#fef5e1',
    danger: colors.dangerRed,
    success: colors.successGreen,
    business: colors.skyBlue,

    // Greys
    grey87: 'rgb(0,0,0,0.87)',
    grey75: 'rgb(0,0,0,0.75)',
    grey67: 'rgb(0,0,0,0.67)',
    grey45: 'rgb(0,0,0,0.45)',
    grey20: 'rgb(0,0,0,0.20)',
    buttonGrey: '#E0E1E2',
    greyText: 'grey',

    // Yellows
    darkYellow: colors.darkYellow,
    starYellow: colors.suiStarYellow,
    starOutline: colors.suiStarOutline,
    starSelected: colors.suiStarSelected,
    starSelectedOutline: colors.suiStarSelectedOutline,

    // Partner Colors
    facebook: '#3b5998',
    twitter: '#0084b4',
    google: '#dd4b39',
    instagram: '#125688',
    foursquare: '#f94877',
    fitbit: '#4cc2c4',
    garmin: '#007cc3',
    pinterest: '#c8232c',
    tripit: '#0782C5',
    linkedin: '#0077b5',
    tumblr: '#34526f',
    getYourGuide: '#ff5433',
    alltrails: '#438716',

    // Color stoplight Colors
    stoplight1of8: '#4caf50', //dark green
    stoplight2of8: '#8bc34a', //light green
    stoplight3of8: '#cddc39', //lime
    stoplight4of8: '#ffeb3b', //yellow
    stoplight5of8: '#ffc107', //light orange
    stoplight6of8: '#ff9800', //orange
    stoplight7of8: '#f44336', //red
    stoplight8of8: '#790019', //maroon
  },

  bg: {
    // Background Colors
    xdark: '#333333',
    dark: '#dddddd',
    default: '#eeeeee',
    light: '#fafafa',
  },

  boxShadow: {
    button: '0px 3px 10px 0px rgba(0,0,0,0.2)',
    card: '0px 2px 18.06px 2.94px rgba(0,33,118,0.11)',
    popups: '0px 2px 18.06px 2.94px rgba(0,33,118,0.11)',
  },

  fontSize: {
    xs: '10px',
    sm: '12px',
    md: '1em',
    lg: '15px',
    xl: '1.5em',
    xxl: '2em',
  },

  borderRadius: {
    rounded: '1000px',
    md: '10px',
    sm: '4px',
  },

  borderWidth: {
    md: '3px',
  },
};

export default rgTheme;
